import { useHttp } from "@/composables/useHttp";

export type CreateReview = {
  reviewer?: string
  review_token: string
  content: string
  ratings: {
    review_subject_id: number
    score: number
  }[]
}

export function useReviewsHttp() {
  const http = useHttp()

  async function createReview(review: CreateReview) {
    return (await http.post('/reviews', review)).data.data
  }

  return {
    createReview,
  }
}
