import { useHttp } from "@/composables/useHttp"

export function useReviewSubjectsHttp() {
const http = useHttp()

  async function getReviewSubject(token: string) {
    const response = await http.get(`/review-subjects/${token}`)

    return response.data.data
  }

  return {
    getReviewSubject,
  }
}
